.hamburger-container {
  position: absolute;
  top: 0;
  right: 0;
}

#modal-container {
  z-index: 5;
  opacity: 0;
  width: 100%;
  height: 100%;
  transition: opacity .4s;
  transition-delay: display .4s;
  pointer-events: none;
  background-color: #2d01534d;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

#modal-container.display-none {
  display: none;
}

#modal-container.open {
  opacity: 1;
  pointer-events: all;
}

.modal {
  color: #000;
  opacity: 0;
  background-color: #f4f4f4;
  border-radius: 6px;
  width: 100%;
  max-width: 450px;
  max-height: 100%;
  transition: opacity .4s, transform .4s;
  overflow-y: auto;
  transform: translateY(25px);
  box-shadow: 0 6px 12px #0002;
}

.modal.open {
  opacity: 1;
  transform: translateY(0);
}

.modal h1 .click, .modal h2 .click {
  color: #80738e;
  font-weight: 400;
}

.modal h1 {
  margin-top: .2em;
  font-size: 1.4em;
}

.modal h2 {
  margin-bottom: .75em;
  font-size: 1em;
}

.modal .content-block {
  padding: 16px;
}

.modal .content-block p:last-of-type {
  margin-bottom: 0;
}

button.hamburger {
  color: #f4f4f4;
  cursor: pointer;
  background-color: #0000;
  border: 2px solid #0000;
  border-radius: 6px;
  width: 50px;
  height: 50px;
  padding: 10px;
  font-size: 1em;
  transition: all .25s;
  position: absolute;
  top: 10px;
  right: 10px;
}

button.hamburger:focus {
  border-color: none;
  color: #000;
  background-color: #fc0;
  outline: none;
}

button.hamburger:focus svg {
  fill: #000;
}

button.hamburger:hover {
  border-color: #7200a4;
}

button.hamburger:active {
  opacity: .5;
}

button.hamburger svg {
  fill: #f4f4f4;
  width: 100%;
  height: 100%;
  display: inline-block;
}

dl.keyboard-shortcuts {
  margin: 0;
  padding-bottom: 5px;
}

dl.keyboard-shortcuts div + div {
  margin-top: .5em;
}

dl.keyboard-shortcuts dd, dl.keyboard-shortcuts dt {
  margin: 0;
  display: inline;
}

dl.keyboard-shortcuts .key {
  color: #444;
  background-color: #eee;
  border: 1px solid #bbb;
  border-bottom: 2px solid #aaa;
  border-radius: 3px;
  margin: 0 .25em;
  padding: 0 6px;
  font-size: .8em;
}

.badge {
  color: #7200a4;
  background-color: #7300a425;
  border-radius: 3px;
  margin-left: 5px;
  padding: 0 5px;
  font-size: 12px;
  font-weight: bold;
}

.button-link {
  text-align: center;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 6px;
  padding: 5px;
  font-size: .85em;
  font-weight: bold;
  text-decoration: none;
  transition: color .4s, border-color .4s, background-color .4s;
  display: block;
}

.button-link:link, .button-link:visited {
  color: #000;
}

.button-link:focus, .button-link:hover {
  color: #7200a4;
  background-color: #7300a411;
  border-color: #7200a4;
  outline: none;
}

#menu-btn-close {
  cursor: pointer;
  background: none;
  border: 1px solid #ccc;
  border-radius: 6px;
  margin-left: auto;
  margin-right: 0;
  padding: 6px 12px;
  font-size: 1em;
  font-weight: 600;
  transition: color .4s, border-color .4s, background-color .4s;
  display: block;
}

#menu-btn-close:focus, #menu-btn-close:hover {
  color: #7200a4;
  background-color: #7300a411;
  border-color: #7200a4;
  outline: none;
}

#menu-btn-close:active {
  opacity: .5;
}

* {
  box-sizing: border-box;
}

body, html {
  color: #000;
  background-color: #1d0428;
  margin: 0;
  padding: 0;
  font-family: futura-pt, Helvetica, Arial, sans-serif;
  font-size: 18px;
  font-weight: 300;
}

body {
  flex-direction: column;
  min-height: 100vh;
  display: flex;
  padding: 0 !important;
}

button, input {
  font-family: futura-pt, Helvetica, Arial, sans-serif;
}

a:link, a:hover {
  color: #005097;
}

a:visited {
  color: #6c3f8a;
}

a:active {
  color: #0080ef;
}

hr {
  background-color: #0000;
  border: 1px solid #fff;
  border-color: #dbdbdb #fff #fff #dbdbdb;
  height: 0;
  margin: 0;
}

.main-container {
  z-index: 1;
  color: #f4f4f4;
  flex-direction: column;
  flex-grow: 1;
  display: flex;
  position: relative;
}

main {
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-around;
  align-items: center;
  padding: 2em 2em max(2em, 160px);
  display: flex;
}

#canvas {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.metronome-container {
  z-index: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  width: 100%;
  max-width: 550px;
  margin-top: 100px;
  display: flex;
  position: relative;
}

.metronome-container .met-left, .metronome-container .met-right {
  flex-basis: 150px;
  position: relative;
}

.metronome-container .met-center {
  width: 100%;
  position: absolute;
  top: -25px;
  left: 0;
}

.metronome-container #tempo-display {
  text-align: center;
  background-image: url("shadow.a5ba6714.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  padding-top: 20px;
  padding-bottom: 32px;
  font-style: italic;
}

.metronome-container #tempo-num {
  margin-right: .04em;
  font-size: 3em;
  font-weight: 600;
}

.metronome-container .bpm {
  font-size: 1.5em;
}

.metronome-container .cbutton {
  cursor: pointer;
  color: #000;
  background-color: #f4f4f4;
  border: none;
  border-radius: 30px;
  width: 50px;
  height: 50px;
  padding: 5px;
  font-size: 26px;
  display: block;
}

.metronome-container .cbutton:active, .metronome-container .cbutton:focus {
  background-color: #fc0;
  outline: none;
}

.metronome-container .cbutton.tap-tempo {
  border-radius: 50%;
  width: 120px;
  height: 120px;
  margin-bottom: 10px;
  font-size: 32px;
  font-weight: bold;
}

.metronome-container .cbutton.start-stop {
  border-radius: 50%;
  width: 60px;
  height: 60px;
  margin-left: 75px;
  padding: 0;
}

.metronome-container .cbutton.start-stop .start, .metronome-container .cbutton.start-stop .stop {
  margin-left: auto;
  margin-right: auto;
}

.metronome-container .cbutton.start-stop .start {
  display: block;
}

.metronome-container .cbutton.start-stop .stop, .metronome-container .cbutton.start-stop[aria-checked="true"] .start {
  display: none;
}

.metronome-container .cbutton.start-stop[aria-checked="true"] .stop {
  display: block;
}

.metronome-container .cbutton.increment {
  margin-bottom: 7px;
  margin-left: 50px;
}

.metronome-container .cbutton.decrement {
  margin-bottom: 7px;
  margin-left: 25px;
}

.pot-label {
  text-align: center;
  margin-top: 8px;
  font-size: 16px;
  display: block;
}

.potmeter {
  cursor: nesw-resize;
  background-color: #f4f4f4;
  border: 2px solid #f4f4f4;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 0;
  font-size: 26px;
  display: block;
}

.potmeter .marker {
  background-color: #1d0428;
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
  width: 4px;
  height: 20px;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

input:focus + .potmeter, .potmeter.active {
  background-color: #fc0;
  border-color: #fc0;
}

input:focus + .potmeter + .pot-label, .potmeter.active + .pot-label {
  color: #fc0;
}

.slider {
  opacity: 0;
  appearance: none;
  background-color: #f4f4f4;
  border: 1px solid #0003;
  border-radius: 10px;
  width: calc(100% + 40px);
  height: 8px;
  margin: 10px -20px;
  position: absolute;
  top: -9000px;
  left: -9000px;
}

@keyframes loading-animation {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

#loading-container {
  z-index: 2;
  color: #fc0;
  background-color: #1d0428;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  font-size: 1.5em;
  transition-property: opacity, visibility;
  transition-duration: .5s, 0s;
  transition-delay: 1s;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

#loading-container .loading-svg {
  transition: transform 1s ease-out, opacity 1s ease-out;
}

#loading-container.hidden {
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition-property: opacity, visibility;
  transition-duration: 2s, 0s;
  transition-delay: 0s, 2s;
}

#loading-container.hidden .loading-svg {
  opacity: 0;
  transform: scale(2);
}

#loading-container.hidden .spinner:before, #loading-container.hidden .spinner:after {
  opacity: 0;
  transform: scale(0);
}

#loading-container .loading-graphic {
  width: 110px;
  height: 110px;
  padding: 30px;
  position: relative;
}

#loading-container .spinner {
  width: 100%;
  height: 100%;
  animation: 3s linear infinite loading-animation;
  position: absolute;
  top: 0;
  left: 0;
}

#loading-container .spinner:before {
  content: "";
  background-color: #fc0;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  transition: transform 1s ease-out, opacity 1s ease-out;
  display: block;
  position: absolute;
  top: 0;
  left: 50px;
}

#loading-container .spinner:after {
  content: "";
  background-color: #fc0;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  transition: transform 1s ease-out, opacity 1s ease-out;
  display: block;
  position: absolute;
  bottom: 0;
  left: 50px;
}

.footer {
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  min-height: 100vh;
  display: flex;
}

.footer section.about {
  background-color: #ff0;
  flex-basis: 50%;
}

.footer section.settings {
  background-color: #0ff;
  flex-basis: 50%;
}

@media screen and (width <= 460px) {
  .metronome-container {
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }

  .metronome-container .met-center {
    top: unset;
    left: unset;
    flex-shrink: 0;
    flex-basis: 100%;
    margin-bottom: 40px;
    position: relative;
  }

  .metronome-container .met-left, .metronome-container .met-right {
    min-width: unset;
    flex: 1 1 0;
  }

  .metronome-container .met-right {
    margin-top: 20px;
  }

  .metronome-container .cbutton, .metronome-container .cbutton.start-stop {
    margin-left: auto;
    margin-right: auto;
  }

  .metronome-container .cbutton.tap-tempo {
    margin-bottom: 20px;
  }
}

.smaller {
  font-size: .85em;
}

.ac {
  width: 100%;
}

@media screen and (width <= 300px) {
  .metronome-container .cbutton.start-stop {
    width: 60px;
    height: 60px;
  }

  .metronome-container .cbutton.tap-tempo {
    width: 60px;
    height: 60px;
    font-size: 16px;
  }

  .metronome-container .pot-label {
    font-size: 14px;
  }
}
/*# sourceMappingURL=index.b7f2e8f2.css.map */
