@import 'colours.scss', 'menus.scss';

* {
	box-sizing: border-box;
}

body,
html {
	margin: 0;
	padding: 0;
	font-family: 'futura-pt', Helvetica, Arial, sans-serif;
	color: $dark-text;
	font-weight: 300;
	font-size: 18px;
	background-color: $bg-color;
}

body {
	padding: 0 !important; // Override adsense style
	min-height: 100vh;
	display: flex;
	flex-direction: column;
}

button,
input {
	font-family: 'futura-pt', Helvetica, Arial, sans-serif;
}

a:link,
a:hover {
	color: #005097;
}

a:visited {
	color: #6c3f8a;
}

a:active {
	color: #0080ef;
}

hr {
	background-color: transparent;
	height: 0;
	border-top: solid 1px darken($offwhite, 10%);
	border-left: solid 1px darken($offwhite, 10%);
	border-bottom: solid 1px #ffffff;
	border-right: solid 1px #ffffff;
	margin: 0;
}

.main-container {
	position: relative;
	z-index: 1;
	color: $offwhite;
	flex-grow: 1;
	display: flex;
	flex-direction: column;
}

main {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-around;

	padding: 2em;
	padding-bottom: max(2em, 160px);
	
	flex-grow: 1;
}

#canvas {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
}

.metronome-container {
	display: flex;
	flex-direction: row;
	position: relative;
	max-width: 550px;
	width: 100%;
	margin-top: 100px;
	z-index: 1;
	justify-content: space-between;
	align-items: stretch;

	.met-left,
	.met-right {
		flex-basis: 150px;
		position: relative;
	}

	.met-center {
		position: absolute;
		top: -25px;
		left: 0;
		width: 100%;
	}

	#tempo-display {
		padding-bottom: 30px;
		text-align: center;
		font-style: italic;
		padding-top: 20px;
		padding-bottom: 32px;
		background-image: url('./assets/shadow.png');
		background-repeat: no-repeat;
		background-position: center;
		background-size: contain;
	}

	#tempo-num {
		font-size: 3em;
		margin-right: 0.04em;
		font-weight: 600;
	}

	.bpm {
		font-size: 1.5em;
	}

	.cbutton {
		display: block;
		width: 50px;
		height: 50px;
		padding: 5px;
		border-radius: 30px;
		background-color: $offwhite;
		border: none;
		font-size: 26px;
		cursor: pointer;
		color: $dark-text;

		&:active,
		&:focus {
			background-color: $accent;
			outline: none;
		}

		&.tap-tempo {
			width: 120px;
			height: 120px;
			border-radius: 50%;
			font-size: 32px;
			font-weight: bold;
			margin-bottom: 10px;
		}

		&.start-stop {
			margin-left: 75px;
			width: 60px;
			height: 60px;
			border-radius: 50%;
			padding: 0;

			.start,
			.stop {
				margin-left: auto;
				margin-right: auto;
			}

			.start {
				display: block;
			}
			.stop {
				display: none;
			}

			&[aria-checked='true'] {
				.start {
					display: none;
				}
				.stop {
					display: block;
				}
			}
		}

		&.increment {
			margin-left: 50px;
			margin-bottom: 7px;
		}

		&.decrement {
			margin-left: 25px;
			margin-bottom: 7px;
		}
	}
}

.pot-container {
}

.pot-label {
	display: block;
	text-align: center;
	margin-top: 8px;
	font-size: 16px;
}

.potmeter {
	display: block;
	width: 60px;
	height: 60px;
	margin-left: auto;
	margin-right: auto;
	border-radius: 50%;
	background-color: $offwhite;
	border: solid 2px $offwhite;
	font-size: 26px;
	cursor: nesw-resize;
	padding-top: 0px;

	.marker {
		display: block;
		width: 4px;
		height: 20px;
		border-bottom-left-radius: 2px;
		border-bottom-right-radius: 2px;
		background-color: $bg-color;
		margin-left: auto;
		margin-right: auto;
	}
}

input:focus + .potmeter,
.potmeter.active {
	border-color: $accent;
	background-color: $accent;
}

input:focus + .potmeter + .pot-label,
.potmeter.active + .pot-label {
	color: $accent;
}

.slider {
	// visibility: hidden;
	opacity: 0;
	position: absolute;
	top: -9000px;
	left: -9000px;
	appearance: none;
	height: 8px;
	border-radius: 10px;
	background-color: $offwhite;
	border: solid 1px #00000033;
	margin-left: -20px;
	margin-right: -20px;
	margin-top: 10px;
	margin-bottom: 10px;
	width: calc(100% + 40px);

	&#volume {
	}

	&#tempo {
	}
}

@keyframes loading-animation {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

#loading-container {
	position: absolute;
	z-index: 2;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;

	background-color: $bg-color;
	color: $accent;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 1.5em;

	transition-duration: 0.5s, 0s;
	transition-property: opacity, visibility;
	transition-delay: 1s;

	.loading-svg {
		transition: transform 1s, opacity 1s;
		transition-timing-function: ease-out;
	}

	&.hidden {
		opacity: 0;
		visibility: hidden;
		transition-duration: 2s, 0s;
		transition-property: opacity, visibility;
		transition-delay: 0s, 2s;
		pointer-events: none;

		.loading-svg {
			transform: scale(2, 2);
			opacity: 0;
		}

		.spinner {
			&::before {
				transform: scale(0, 0);
				opacity: 0;
			}
			&::after {
				transform: scale(0, 0);
				opacity: 0;
			}
		}
	}

	.loading-graphic {
		position: relative;
		width: 110px;
		height: 110px;
		padding: 30px;
	}

	.spinner {
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		animation: loading-animation 3s infinite linear;

		@mixin loading-dot($position) {
			$size: 10px;
			content: '';
			position: absolute;
			display: block;
			width: $size;
			height: $size;
			left: 55px - $size / 2;
			border-radius: 50%;
			background-color: $accent;
			transition: transform 1s, opacity 1s;
			transition-timing-function: ease-out;

			@if $position == 'top' {
				top: 0;
			} @else {
				bottom: 0;
			}
		}

		&::before {
			@include loading-dot('top');
		}

		&::after {
			@include loading-dot('bottom');
		}
	}
}

.footer {
	min-height: 100vh;
	display: flex;
	flex-direction: row;
	align-items: stretch;
	justify-content: space-between;

	section.about {
		background-color: #ffff00;
		flex-basis: 50%;
	}

	section.settings {
		background-color: #00ffff;
		flex-basis: 50%;
	}
}

@media screen and (max-width: 460px) {
	.metronome-container {
		flex-wrap: wrap;
		justify-content: center;
		align-items: center;

		.met-center {
			position: relative;
			top: unset;
			left: unset;
			flex-basis: 100%;
			flex-shrink: 0;
			margin-bottom: 40px;
		}

		.met-left,
		.met-right {
			flex-basis: 0;
			min-width: unset;
			flex-shrink: 1;
			flex-grow: 1;
		}

		.met-right {
			margin-top: 20px;
		}

		.cbutton {
			&.start-stop {
				margin-left: auto;
				margin-right: auto;
			}

			&.tap-tempo {
				margin-bottom: 20px;
			}

			margin-left: auto;
			margin-right: auto;
		}
	}
}

.smaller {
	font-size: 0.85em;
}

.ac {
	width: 100%;
}

@media screen and (max-width: 300px) {
	.metronome-container {
		.cbutton {
			&.start-stop {
				width: 60px;
				height: 60px;
			}

			&.tap-tempo {
				width: 60px;
				height: 60px;
				font-size: 16px;
			}
		}

		.pot-label {
			font-size: 14px;
		}
	}
}
