@import 'colours.scss';

.hamburger-container {
	position: absolute;
	top: 0;
	right: 0;
}

#modal-container {
	position: absolute;
	top: 0;
	left: 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	z-index: 5;
	width: 100%;
	height: 100%;
	background-color: rgba(rgb(45, 1, 83), 0.3);
	padding: 20px;
	overflow: hidden;

	// Hidden
	transition: opacity 0.4s;
	opacity: 0;
	transition-delay: display 0.4s;
	pointer-events: none;

	&.display-none {
		display: none;
	}

	// Visible
	&.open {
		opacity: 1;
		pointer-events: all;
	}
}

.modal {
	background-color: $offwhite;
	color: $dark-text;
	border-radius: 6px;
	width: 100%;
	max-width: 450px;
	max-height: 100%;
	box-shadow: 0 6px 12px 0 #0002;
	transition: opacity 0.4s, transform 0.4s;
	overflow-y: auto;
	opacity: 0;
	transform: translateY(25px);

	&.open {
		opacity: 1;
		transform: translateY(0px);
	}

	h1,
	h2 {
		.click {
			color: rgb(128, 115, 142);
			font-weight: 400;
		}
	}

	h1 {
		font-size: 1.4em;
		margin-top: 0.2em;
	}

	h2 {
		font-size: 1em;
		margin-bottom: 0.75em;
	}

	.content-block {
		padding: 16px 16px;

		p:last-of-type {
			margin-bottom: 0;
		}
	}
}

button.hamburger {
	position: absolute;
	top: 10px;
	right: 10px;
	border: solid 2px transparent;
	border-radius: 6px;
	padding: 0;
	width: 50px;
	height: 50px;
	padding: 10px;
	background-color: transparent;
	color: $offwhite;
	font-size: 1em;
	cursor: pointer;

	transition: all 0.25s;

	&:focus {
		border-color: none;
		outline: none;
		background-color: $accent;
		color: $dark-text;

		svg {
			fill: $dark-text;
		}
	}

	&:hover {
		border-color: #7200a4;
	}

	&:active {
		opacity: 0.5;
	}

	svg {
		display: inline-block;
		width: 100%;
		height: 100%;
		fill: #f4f4f4;
	}
}

dl.keyboard-shortcuts {
	margin: 0;
	padding-bottom: 5px;

	div + div {
		margin-top: 0.5em;
	}

	dd,
	dt {
		display: inline;
		margin: 0;
	}

	.key {
		margin: 0 0.25em;
		font-size: 0.8em;
		background-color: #eee;
		border: solid 1px #bbb;
		border-bottom-width: 2px;
		border-bottom-color: #aaa;
		border-radius: 3px;
		padding: 0 6px;
		color: #444;
	}
}

.badge {
	color: #7200a4;
	background-color: #7300a425;
	border-radius: 3px;
	font-size: 12px;
	font-weight: bold;
	padding: 0 5px;
	margin-left: 5px;
}

.button-link {
	display: block;
	border: solid 1px #ccc;
	padding: 5px;
	border-radius: 6px;
	background-color: #fff;
	text-decoration: none;
	font-weight: bold;
	font-size: 0.85em;
	text-align: center;
	transition: color 0.4s, border-color 0.4s, background-color 0.4s;

	&:link,
	&:visited {
		color: $dark-text;
	}

	&:focus,
	&:hover {
		border-color: #7200a4;
		color: #7200a4;
		background-color: #7300a411;
		outline: none;
	}
}

#menu-btn-close {
	display: block;
	border: solid 1px #ccc;
	border-radius: 6px;
	padding: 6px 12px;
	background: none;
	background-color: transparent;
	font-size: 1em;
	cursor: pointer;
	font-weight: 600;
	transition: color 0.4s, border-color 0.4s, background-color 0.4s;
	margin-left: auto;
	margin-right: 0;

	&:focus,
	&:hover {
		border-color: #7200a4;
		color: #7200a4;
		background-color: #7300a411;
		outline: none;
	}

	&:active {
		opacity: 0.5;
	}
}
